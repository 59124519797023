import { langArr } from '../js/lang.js';

document.addEventListener('DOMContentLoaded', function () {
  // Получаем ссылки и секции
  var links = document.querySelectorAll(
    '.section-link, .advantages-link, .menu-link, .menu-modal'
  );
  var sections = document.querySelectorAll('.switch');

  // Скрываем все секции, кроме секции "about-us"
  sections.forEach(function (section) {
    if (section.id !== 'about-us') {
      section.style.display = 'none';
    } else {
      section.classList.add('active-section'); // Добавляем класс "active-section" к секции "about-us"
    }
  });

  // Обрабатываем клик по ссылке
  links.forEach(function (link) {
    link.addEventListener('click', function (e) {
      e.preventDefault(); // Предотвращаем переход по ссылке

      // Получаем ID целевой секции из атрибута href
      var target = this.getAttribute('href');

      // Скрываем все секции
      sections.forEach(function (section) {
        section.style.display = 'none';
        section.classList.remove('active-section'); // Удаляем класс "active-section" у всех секций
      });

      // Показываем целевую секцию
      var targetSection = document.querySelector(target);
      if (targetSection) {
        targetSection.style.display = 'block';
        targetSection.classList.add('active-section'); // Добавляем класс "active-section" к целевой секции
        // targetSection.scrollIntoView({ behavior: "smooth" });

        var scrollOffset = targetSection.getBoundingClientRect().top - 320;
        window.scrollBy({
          top: scrollOffset,
          left: 0,
          behavior: 'smooth',
        });
      }

      // Подсвечиваем активные кнопки навигации и адвантаджес
      links.forEach(function (link) {
        link.classList.remove('active-link'); // Удаляем класс "active-link" у всех кнопок навигации и адвантаджес
      });

      // Подсветить активную кнопку навигации, соответствующую выбранной секции адвантаджес
      var menuLinks = document.querySelectorAll('.menu-link');
      menuLinks.forEach(function (menuLink) {
        if (menuLink.getAttribute('href') === target) {
          menuLink.classList.add('active-link');
        }
      });

      // Подсветить активный раздел адвантаджес, соответствующий выбранной кнопке навигации
      var advantagesLinks = document.querySelectorAll('.advantages-link');
      advantagesLinks.forEach(function (advantagesLink) {
        if (advantagesLink.getAttribute('href') === target) {
          advantagesLink.classList.add('active-link');
        } else if (target === './') {
          // Добавляем проверку для раздела "Домой"
          advantagesLink.classList.remove('active-link');
          document
            .querySelector(".advantages-link[href='#about-us']")
            .classList.add('active-link');
        }
      });
    });
  });
});

const select = document.querySelector('.change-lang');
const allLang = ['en', 'ru', 'ua'];

// document.querySelectorAll('.left-panel img').forEach(item => item.onmouseenter = (event) => maxImg.src = event.target.src);

select.addEventListener('change', changeURLLanguage);

// перенаправить на url с указанием языка
function changeURLLanguage() {
  let lang = select.value;
  location.href = window.location.pathname + '#' + lang;
  location.reload();
}

function changeLanguage() {
  let hash = window.location.hash;
  hash = hash.substr(1);
  // console.log(hash);
  if (!allLang.includes(hash)) {
    location.href = window.location.pathname + '#en';
    location.reload();
  }
  select.value = hash;
  //document.querySelector('title').innerHTML = langArr['unit'][hash];
  // document.querySelector('.lng-chip').innerHTML = langArr['chip'][hash];
  for (let key in langArr) {
    let elem = document.querySelector('.lng-' + key);
    if (elem) {
      elem.innerHTML = langArr[key][hash];
    }
  }

  // Пример обновления языка элементов в мобильном меню
  // const mobileMenuItems = document.querySelectorAll('.menu-modal-item a');
  // mobileMenuItems.forEach(item => {
  //   const key = item.classList[1]; // Название класса, например, 'lng-about-menu'
  //   const translatedText = langArr[key][hash];
  //   item.textContent = translatedText;
  // });
}



changeLanguage();
