import { langArr } from '../js/lang.js';
(() => {
  const mobileMenu = document.querySelector('.js-menu-container');
  const openMenuBtn = document.querySelector('.js-open-menu');
  const closeMenuBtn = document.querySelector('.js-close-menu');
  const linksMenu = document.querySelectorAll('.menu-modal');

  const toggleMenu = () => {
    const isMenuOpen =
      openMenuBtn.getAttribute('aria-expanded') === 'true' || false;
    openMenuBtn.setAttribute('aria-expanded', !isMenuOpen);
    mobileMenu.classList.toggle('is-open');
  };

  openMenuBtn.addEventListener('click', toggleMenu);
  closeMenuBtn.addEventListener('click', toggleMenu);
  linksMenu.forEach(link => link.addEventListener('click', toggleMenu));

 
  const allLang = ['en', 'ru', 'ua'];
  function changeLanguage() {
    let hash = window.location.hash;
    hash = hash.substr(1);    
    if (!allLang.includes(hash)) {
      location.href = window.location.pathname + '#en';
      location.reload();
    }
    linksMenu.forEach(link => {
      const key = link.classList[1].substr(4);      
      const translatedText = langArr[key][hash];
      link.textContent = translatedText;
    });    
  }
  // Обработчик события для кнопки открытия мобильного меню
  
  openMenuBtn.addEventListener('click', () => {
    changeLanguage();
  });

  // Close the mobile menu on wider screens if the device orientation changes
  window.matchMedia('(min-width: 768px)').addEventListener('change', e => {
    if (!e.matches) return;
    mobileMenu.classList.remove('is-open');
    openMenuBtn.setAttribute('aria-expanded', false);
  });
})();
