export const langArr = {
  'hero-caption': {
    ru: `Европейский <em>Сертификационно Экспертный Центр</em> продукции, услуг и систем
        <em> АСУ БАЛТИК </em>`,
    en: `European <em>Certification Expert Center</em> of Products, Services, and Management Systems
        <em> ASU BALTIC </em>`,
    ua: `Європейський <em>Сертіфікаційно Експертний Центр</em> продукції, послуг та систем
        <em> АСУ БАЛТІК </em>`,
  },
  'advantages-about': {
    ru: `
      <h3 class="advantages-title">О Нас</h3>
      <p>
      Сертификация промышленной продукции и систем по требованиям директив ЕС.
      </p>
      `,
    en: `
        <h3 class="advantages-title">About Us</h3>
        <p>
        Certification of industrial products and systems according to the requirements of EU directives.
        </p>
      
  `,
    ua: `
        <h3 class="advantages-title">Про Нас</h3>
        <p>
        Сертифікація промислової продукції та систем за вимогами директив ЄС
        </p>      
  `,
  },
  'advantages-ce': {
    ru: `
      <h3 class="advantages-title">Сертификация продукции</h3>
      <p>
        Детальная информация о процедуре сертификации, 
        знаке соответствия и сфере его применения.
      </p>`,
    en: `
        <h3 class="advantages-title">Product Certification</h3>
        <p>
          Detailed information about the certification procedure, 
          conformity mark, and its application scope.
        </p>
  `,
    ua: `
        <h3 class="advantages-title">Сертифікація Продукції</h3>
        <p>
          Детальна інформація про процедуру сертифікації, 
          знак відповідності та його сферу застосування.
        </p>
  `,
  },
  'advantages-cert': {
    ru: `
      <h3 class="advantages-title">Сертификация систем управления</h3>
      <p>
        Сертификация, аудит, надзор и ресертификация услуг,
                    товаров и систем управления.
      </p>`,
    en: `
        <h3 class="advantages-title">Management System Certification</h3>
        <p>
          Certification, auditing, supervision, and recertification of services, 
          goods, and management systems.
        </p>
  `,
    ua: `
        <h3 class="advantages-title">Сертифікація Систем Управління</h3>
        <p>
          Сертифікація, аудит, нагляд та ресертифікація послуг,
          товарів та систем управління.
        </p>
  `,
  },
  'about-menu': {
    ru: 'О нас',
    en: 'About us',
    ua: "Про нас",
  },
  'ce-menu': {
    ru: 'Сертификация продукции',
    en: 'Products certification',
    ua: 'Сертифікація продукції',
  },
  'cert-menu': {
    ru: 'Сертификация систем',
    en: 'Systems certification',
    ua: 'Сертифікація систем',
  },
  'check-menu': {
    ru: 'Проверка сертификата',
    en: 'Check certificate',
    ua: 'Перевірка сертифіката',
  },
  'info-menu': {
    ru: 'Информация для заказчиков',
    en: 'Customers info',
    ua: 'Інформація для замовників',
  },
  'contacts-menu': {
    ru: 'Контакты',
    en: 'Contacts',
    ua: 'Контакти',
  },
  'cont-name': {
    ru: 'Ваше имя',
    en: 'Your name',
    ua: "Ваше ім'я",
  },
  'cont1-name': {
    ru: 'Ваше имя',
    en: 'Your name',
    ua: "Ваше ім'я",
  },
  'cont-company': {
    ru: 'Ваша организация',
    en: 'Your company name',
    ua: "Ваша організація",
  },
  'cont-email': {
    ru: 'Ваш адрес email',
    en: 'Your email',
    ua: "Ваша адреса email",
  },
  'cont1-email': {
    ru: 'Ваш адрес email',
    en: 'Your email',
    ua: "Ваша адреса email",
  },
  'cont-number': {
    ru: 'Номер сертификата',
    en: 'Certificate number',
    ua: "Номер сертифікату",
  },
  'cont-iss-company': {
    ru: 'Организация, которой выдан сертификат',
    en: 'The organization that received the certificate',
    ua: "Організація, яка отримала сертифікат",
  },
  'cont-txt': {
    ru: 'Ваше сообщение',
    en: 'Your message or question',
    ua: "Ваше повідомлення",
  },
  'cont-submit': {
    ru: 'Отправить',
    en: 'Submit',
    ua: "Відправити",
  },
  'cont1-submit': {
    ru: 'Отправить',
    en: 'Submit',
    ua: "Відправити",
  },
  'asu-name': {
    ru: 'ЗАО "СЕРТИФИКАЦИОННО ЭКСПЕРТНЫЙ ЦЕНТР АСУ БАЛТИК"',
    en: 'LLC "Certification expert center ASU BALTIC"',
    ua: 'ТОВ "СЕРТИФІКАЦІЙНО ЕКСПЕРТНИЙ ЦЕНТР АСУ БАЛТІК"',
  },
  'our-contacts': {
    ru: `<span>Наши </span>контакты`,
    en: `<span>Our </span>contacts`,
    ua: `<span>Наші </span>контакти`,
  },
  'tel-latvia': {
    ru: 'Латвия',
    en: 'Latvia',
    ua: "Латвія",
  },
  'tel-ukr': {
    ru: 'Украина',
    en: 'Ukraine',
    ua: "Україна",
  },
  'tel-bolg': {
    ru: 'Болгария',
    en: 'Bulgaria',
    ua: "Болгарія",
  },
  'tel-latvia1': {
    ru: 'Латвия',
    en: 'Latvia',
    ua: "Латвія",
  },
  'tel-ukr1': {
    ru: 'Украина',
    en: 'Ukraine',
    ua: "Україна",
  },
  'tel-bolg1': {
    ru: 'Болгария',
    en: 'Bulgaria',
    ua: "Болгарія",
  },
  'map': {
    ru: `<p class="full-addr"><b> Stiklu iela 18, Даугавпилс, LV-5404, Латвия</b></p>
    <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.3322439953363!2d26.56824587727602!3d55.87425117312947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46c295aa12eb0d23%3A0xcf51d19016feeddb!2zU3Rpa2x1IGllbGEgMTgsIERhdWdhdnBpbHMsIExWLTU0MDQsINCb0LDRgtCy0LjRjw!5e0!3m2!1sru!2spl!4v1691056624401!5m2!1sru!2spl" target="_blank" rel="noreferrer noopener">          
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.3322439953363!2d26.56824587727602!3d55.87425117312947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46c295aa12eb0d23%3A0xcf51d19016feeddb!2zU3Rpa2x1IGllbGEgMTgsIERhdWdhdnBpbHMsIExWLTU0MDQsINCb0LDRgtCy0LjRjw!5e0!3m2!1sru!2spl!4v1691056624401!5m2!1sru!2spl" width="100%" height="320px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </a>`,
    en: `<p class="full-addr"><b> Stiklu iela 18, Daugavpils, LV-5404, Latvia</b></p>
    <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.3322439953363!2d26.56824587727602!3d55.87425117312947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46c295aa12eb0d23%3A0xcf51d19016feeddb!2zU3Rpa2x1IGllbGEgMTgsIERhdWdhdnBpbHMsIExWLTU0MDQsINCb0LDRgtCy0LjRjw!5e0!3m2!1sen!2spl!4v1691056624401!5m2!1sen!2spl" target="_blank" rel="noreferrer noopener">          
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.3322439953363!2d26.56824587727602!3d55.87425117312947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46c295aa12eb0d23%3A0xcf51d19016feeddb!2zU3Rpa2x1IGllbGEgMTgsIERhdWdhdnBpbHMsIExWLTU0MDQsINCb0LDRgtCy0LjRjw!5e0!3m2!1sen!2spl!4v1691056624401!5m2!1sen!2spl" width="100%" height="320px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </a>`,
    ua: `<p class="full-addr"><b> Stiklu iela 18, Дауґавпілс, LV-5404, Латвія</b></p>
    <a href="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.3322439953363!2d26.56824587727602!3d55.87425117312947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46c295aa12eb0d23%3A0xcf51d19016feeddb!2zU3Rpa2x1IGllbGEgMTgsIERhdWdhdnBpbHMsIExWLTU0MDQsINCb0LDRgtCy0LjRjw!5e0!3m2!1suk!2spl!4v1691056624401!5m2!1suk!2spl" target="_blank" rel="noreferrer noopener">          
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2238.3322439953363!2d26.56824587727602!3d55.87425117312947!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46c295aa12eb0d23%3A0xcf51d19016feeddb!2zU3Rpa2x1IGllbGEgMTgsIERhdWdhdnBpbHMsIExWLTU0MDQsINCb0LDRgtCy0LjRjw!5e0!3m2!1suk!2spl!4v1691056624401!5m2!1suk!2spl" width="100%" height="320px" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
    </a>`,
  },
  'send-ask': {
    ru: `Мы <span>всегда</span> на связи`,
    en: `We are <span>always</span> in touch`,
    ua: `Ми <span>завжди</span> на зв'язку`,
  },
  'check-cert': {
    ru: `<span>Проверка </span>сертификата`,
    en: `<span>Check </span>your certificate`,
    ua: `<span>Перевірка </span>сертифіката`,
  },
  'slogan': {
    ru: `<span class="accent-footer">Сертификат CE:</span> Создание моста к совершенству`,
    en: `<span class="accent-footer">CE Certified:</span> Building a Bridge to Excellence`,
    ua: `<span class="accent-footer">Сертифікат CE:</span> Побудова мосту до досконалості`,
  },
  'footer-cont': {
    ru: "Свяжитесь с нами",
    en: 'Contact us',
    ua: "Зв'яжіться з нами",
  },
  info:{
    ru: `Информация для <span> заказчиков</span>`,
    en: `Information for <span> customers</span>`,
    ua: `Інформація для <span> замовників</span>`,
  },
  'info-1':{
    ru: "Порядок сертификации",
    en: 'Certification procedure',
    ua: "Порядок сертифікації",
  },
  'info-2':{
    ru: "Знак АСУ БАЛТИК",
    en: 'Badge of ASU BALTICK',
    ua: "Знак АСУ БАЛТІК",
  },
  'info-3':{
    ru: "Беспристрастность",
    en: 'Impartiality',
    ua: "Неупередженість",
  },
  'info-4':{
    ru: "Жалобы",
    en: 'Complaints',
    ua: "Скарги",
  },
  about: {
    ru: `<h2 class="about-us-title">
        О 
        <span class="about-us-title-accent">НАС</span>
      </h2>
      <p class="about-us-subtitle">
        <strong
                      >СЕРТИФИКАЦИОННЫЙ ЭКСПЕРТНЫЙ ЦЕНТР АСУ БАЛТИК. 
                    </strong>
                    Сертификация промышленной продукции и систем по требованиям директив ЕС.
                 Оценка соответствия и сертификации по международным стандартам.
      </p>
      <div class="about-us-texts">
      <div class="left">
      <h3 class="lng-left-title">Орган по сертификации продукции</h3>
      <p class="lng-left">в сфере действия директив</p>
      <ul class="about-list">
        <li class="about-step lng-left-item1">2014/34/EU (ATEX) Оборудование и защитные системы, предназначенные для использования в потенциально взрывоопасных средах;</li>
        <li class="about-step lng-left-item2">REGULATION No. 2016/425 (PPE) Средства индивидуальной защиты;</li>        
      </ul>
      </div>
      <div class="center">        
        <h3 class="lng-center-title">Орган сертификации продукции и услуг в системе сертификации АСУ БАЛТИК</h3>
        <p  class="lng-center">Оценка соответствия требованиям международных стандартов воспринимается как подтверждение того, 
          что прогрессивная организация планомерно и систематически следит за нормативами и техническими документами в отрасли. 
          Это стратегическое и всеобъемлющее решение.
          Полученный в АСУ БАЛТИК сертификат дает заказчику следующие преимущества:</p>
        <ul class="about-list">
          <li class="about-step lng-center-item1">доверие к продукции/услуге/системе управления;</li>
          <li class="about-step lng-center-item2">гарантию качества и конкурентоспособность;</li>
          <li class="about-step lng-center-item3">представление достоверной информации конечному потребителю;        </li>
          <li class="about-step lng-center-item4">снижение вероятности рекламаций.</li>        
        </ul>
        <p class="lng-center">Работы по оценке соответствия и сертификации выполняются высококвалифицированными специалистами и аудиторами с многолетним стажем.</p>
      </div>
      <div class="right">        
        <h3 class="lng-right-title">Орган по сертификации систем управления</h3>
        <ul class="about-list">
          <li class="about-step lng-right-item1">EN ISO 9001 Системы управления качеством;</li>
          <li class="about-step lng-right-item2">EN ISO 14001 Системы управления окружающей средой;</li>
          <li class="about-step lng-right-item3">
            EN ISO 45001 Системы управления по охране здоровья и
            безопасным условиям труда;
          </li>
          <li class="about-step lng-right-item4">EN ISO 13485 - Медицинские изделия;</li>
          <li class="about-step lng-right-item5">Стандарт GMP - Good Manufacturing Practice</li>
          <li class="about-step lng-right-item6">
            EN ISO 22000 - Система управления безопасностью пищевых
            продуктов;
          </li>
          <li class="about-step lng-right-item7">EN ISO 50001 Системы управления энергией;</li>
          <li class="about-step lng-right-item8">
            EN ISO 39001 Системы управления безопасностью дорожного
            движения;
          </li>
          <li class="about-step lng-right-item9">
            EN ISO 27001 Системы управления информационной
            безопасностью.
          </li>
        </ul>
      </div>

    </div>`,
    en: `<h2 class="about-us-title">
    About 
    <span class="about-us-title-accent">US</span>
  </h2>
  <p class="about-us-subtitle">
    <strong
                  >CERTIFICATION EXPERT CENTER ASU BALTIC. 
                </strong>
                Certification of industrial products and systems according to the requirements of EU directives.
                 Conformity assessment and certification according to international standards.
  </p>
  <div class="about-us-texts">
  <div class="left">
  <h3 class="lng-left-title">Product Certification Body</h3>
  <p class="lng-left">in the scope of directives</p>
  <ul class="about-list">
    <li class="about-step lng-left-item1">2014/34/EU (ATEX) Equipment and protective systems intended for use in potentially explosive atmospheres;</li>
    <li class="about-step lng-left-item2">REGULATION No. 2016/425 (PPE) Personal Protective Equipment;</li>        
  </ul>
  </div>
  <div class="center">        
    <h3 class="lng-center-title">Product and Service Certification Body in the ASU BALTIC Certification System</h3>
    <p  class="lng-center">Conformity assessment to international standards is perceived as confirmation that a progressive organization systematically adheres to industry norms and technical documents. It is a strategic and comprehensive decision.
      The certificate obtained from ASU BALTIC provides the customer with the following advantages:</p>
    <ul class="about-list">
      <li class="about-step lng-center-item1">trust in the product/service/management system;</li>
      <li class="about-step lng-center-item2">quality assurance and competitiveness;</li>
      <li class="about-step lng-center-item3">provision of credible information to the end consumer;</li>
      <li class="about-step lng-center-item4">reduced likelihood of claims.</li>        
    </ul>
    <p class="lng-center">Conformity assessment and certification work is carried out by highly qualified specialists and auditors with many years of experience.</p>
  </div>
  <div class="right">        
    <h3 class="lng-right-title">Management System Certification Body</h3>
    <ul class="about-list">
      <li class="about-step lng-right-item1">EN ISO 9001 Quality Management Systems;</li>
      <li class="about-step lng-right-item2">EN ISO 14001 Environmental Management Systems;</li>
      <li class="about-step lng-right-item3">
        EN ISO 45001 Occupational Health and Safety Management Systems;
      </li>
      <li class="about-step lng-right-item4">EN ISO 13485 - Medical Devices;</li>
      <li class="about-step lng-right-item5">GMP - Good Manufacturing Practice</li>
      <li class="about-step lng-right-item6">
        EN ISO 22000 - Food Safety Management System;
      </li>
      <li class="about-step lng-right-item7">EN ISO 50001 Energy Management Systems;</li>
      <li class="about-step lng-right-item8">
        EN ISO 39001 Road Traffic Safety Management Systems;
      </li>
      <li class="about-step lng-right-item9">
        EN ISO 27001 Information Security Management Systems.
      </li>
    </ul>
  </div>

</div>

      `,
    ua: `<h2 class="about-us-title">
    Про 
    <span class="about-us-title-accent">НАС</span>
  </h2>
  <p class="about-us-subtitle">
    <strong
                  >СЕРТИФІКАЦІЙНИЙ ЕКСПЕРТНИЙ ЦЕНТР АСУ БАЛТІК.  
                </strong>
                Сертифікація промислової продукції та систем за вимогами директив ЄС.
                Оцінка відповідності та сертифікації згідно міжнародним стандартам.
  </p>
  <div class="about-us-texts">
  <div class="left">
  <h3 class="lng-left-title">Орган з сертифікації продукції</h3>
  <p class="lng-left">у сфері дії директив</p>
  <ul class="about-list">
    <li class="about-step lng-left-item1">2014/34/EU (ATEX) Обладнання та захисні системи, призначені для використання в потенційно вибухонебезпечних середовищах;</li>
    <li class="about-step lng-left-item2">REGULATION No. 2016/425 (PPE) Засоби індивідуального захисту;</li>        
  </ul>
  </div>
  <div class="center">        
    <h3 class="lng-center-title">Орган сертифікації продукції та послуг в системі сертифікації АСУ БАЛТІК</h3>
    <p  class="lng-center">Оцінка відповідності вимогам міжнародних стандартів розглядається як підтвердження того, що прогресивна організація планомірно та систематично слідкує за нормативами та технічними документами у галузі. Це стратегічне і всеосяжне рішення.
      Отриманий в АСУ БАЛТІК сертифікат надає замовнику наступні переваги:</p>
    <ul class="about-list">
      <li class="about-step lng-center-item1">довіра до продукції/послуги/системи управління;</li>
      <li class="about-step lng-center-item2">гарантія якості та конкурентоздатність;</li>
      <li class="about-step lng-center-item3">подання достовірної інформації кінцевому споживачу;</li>
      <li class="about-step lng-center-item4">зниження ймовірності рекламацій.</li>        
    </ul>
    <p class="lng-center">Роботи з оцінки відповідності та сертифікації виконуються висококваліфікованими спеціалістами та аудиторами з багаторічним досвідом.</p>
  </div>
  <div class="right">        
    <h3 class="lng-right-title">Орган з сертифікації систем управління</h3>
    <ul class="about-list">
      <li class="about-step lng-right-item1">EN ISO 9001 Системи управління якістю;</li>
      <li class="about-step lng-right-item2">EN ISO 14001 Системи управління навколишнім середовищем;</li>
      <li class="about-step lng-right-item3">
        EN ISO 45001 Системи управління охороною здоров'я та
        безпечними умовами праці;
      </li>
      <li class="about-step lng-right-item4">EN ISO 13485 - Медичні вироби;</li>
      <li class="about-step lng-right-item5">Стандарт GMP - Добра практика виробництва</li>
      <li class="about-step lng-right-item6">
        EN ISO 22000 - Система управління безпекою харчових
        продуктів;
      </li>
      <li class="about-step lng-right-item7">EN ISO 50001 Системи управління енергією;</li>
      <li class="about-step lng-right-item8">
        EN ISO 39001 Системи управління безпекою дорожнього
        руху;
      </li>
      <li class="about-step lng-right-item9">
        EN ISO 27001 Системи управління інформаційною
        безпекою.
      </li>
    </ul>
  </div>

</div>

      `,
  },
  ce: {
    ru: `<h2 class="ce-us-title">
    <span class="ce-us-title-accent">Сертификация</span> продукции
  </h2>

  <h3>
    Регламент № 2016/425
    <p>Средства индивидуальной защиты (СИЗ).</p>
  </h3>
  <p>Область применения:</p>
  <ul class="ce-list">
    <li>
      а) оборудование, которое человек носит или использует, чтобы защититься
      от одного или нескольких рисков, угрожающих его здоровью или
      безопасности;
    </li>
    <li>
      б) сменные компоненты оборудования, указанного в пункте а), которые
      необходимы для того, чтобы оно выполняло защитные функции;
    </li>
    <li>
      в) соединительные системы оборудования, указанного в пункте а). С их
      помощью оборудование подключается к внешним устройствам или
      присоединяется к надежной точке крепления, которые не спроектированы как
      постоянно несъемные и не требуют прикрепления перед применением.
    </li>
  </ul>
  <p>СИЗ классифицируются в соответствии с категориями рисков:</p>
  <ul class="ce-list">
    <li>
      Категория I включает - следующие минимальные риски – поверхностное
      механическое повреждение: контакт с моющими средствами слабого действия
      или длительный контакт с водой; контакт с горячими поверхностями,
      температура которых не превышает 50 °C; повреждение глаз из-за
      воздействия солнечного света (кроме наблюдения за солнцем); атмосферные
      условия, не имеющие экстремального характера.
    </li>
    <li>
      Категория II включает риски, не относящиеся к перечисленным в категориях
      I и III.
    </li>
    <li>
      Категория III включает исключительно риски, которые могут привести к
      серьезным последствиям - нанести смерть или необратимый вред здоровью
      пользователя, что касается таких факторов: вещества и смеси, опасные для
      здоровья; среды с дефицитом кислорода; вредные биологические агенты;
      ионизирующее излучение; среды с высокой температурой, воздействие
      которых сравнивается со средами с температурой воздуха не менее 100°C;
      среды с низкой температурой, воздействие которых сравнивается со средами
      с температурой воздуха – 50°C или ниже; падение с высоты; поражение
      электрическим током и работа под напряжением; утопление; порезы от
      ручных бензопил; струи высокого давления; огнестрельные раны или ранения
      ножом; вредный шум.
    </li>
  </ul>
  <p>Процедуры оценки соответствия:</p>
  <ul class="ce-list">
    <li>Для категории I - Внутренний контроль производства (модуль А)</li>
    <li>
      Для категории II - Экспертиза типа (модуль В) и оценка соответствия
      типу, основанная на внутреннем производственном контроле (модуль С)
    </li>
    <li>
      Для категории III Экспертиза типа (модуль В) и на выбор производителя
      любой из перечисленных ниже модулей:
      <ul class="ce-list">
        <li>
          оценка соответствия типу, основанная на внутреннем контроле
          производства совместно с контролируемыми проверками продукции,
          проводимыми в случайные промежутки времени (модуль С2)
        </li>
        <li>
          оценка соответствия типу, основанная на процессе обеспечении
          качества продукции (модуль D)
        </li>
      </ul>
    </li>
    <li>
      Для единичной продукции категории ІІІ – Экспертиза типа (модуль В) и
      оценка соответствия типу, основанная на внутреннем производственном
      контроле (модуль С).
    </li>
  </ul>
  <p>&nbsp;</p>
  <h3>Директива 2014/34/EU (ATEX)</h3>
  <p>Область применения:</p>
  <p>
    Оборудование и защитные
    системы, предназначенные для использования в потенциально взрывоопасных
    средах; защитные, контролирующие и регулирующие устройства,
    предназначенные для использования вне потенциально взрывоопасных сред,
    которые необходимы или способствуют взрывобезопасному функционированию
    оборудования и защитных систем; компоненты, предназначенные для установки
    в оборудование и защитные системы, предназначены для использования в
    потенциально взрывоопасных средах.
  </p>
  <p>Оборудование делится на следующие группы:</p>
  <ul class="ce-list">
    <li>
      группа I – оборудование, предназначенное для использования в подземных
      частях шахт и в частях наземных установок шахт, которые могут оказаться
      под угрозой появления рудничного газа и/или горючей пыли, в том числе
      оборудования категорий М1 и М2;
    </li>
    <li>
      группа II – оборудование, предназначенное для использования в местах,
      отличных от шахт или наземных шахтных установок, которые могут оказаться
      под угрозой появления взрывоопасных сред, включая оборудование категорий
      1, 2 и 3.
    </li>
  </ul>
  <p>
    Оборудование категории М1 и категории 1 должно обеспечивать сверхвысокий
    уровень защиты.
  </p>
  <p>
    Оборудование категории М2 и категории 2 должно обеспечивать высокий
    уровень защиты.
  </p>
  <p>
    Оборудование категории 3 – должно обеспечивать нормальный уровень защиты.
  </p>
  <p>Процедура оценки соответствия директиве 2014/34/EU</p>
  <ul class="ce-list">
    <li>
      1) для оборудования категории М1 группы I и категории 1 группы II:
      Испытания типа (Приложение III к директиве 2014/34/EU) в сочетании с
      одной из следующих процедур: Соответствие типу на основе обеспечения
      качества процесса производства (Приложение IV к директиве 2014/34/EU)
      Соответствие типа на основе верификации продукции (Приложение V к
      директиве 2014/34/EU)
    </li>
    <li>
      2) для оборудования категории М2 группы I и категории 2 группы II:
      <ul class="ce-list">
        <li>
          а) Двигатели внутреннего сгорания и электрическое оборудование
          проходят испытания типа (Приложение III к директиве 2014/34/EU) в
          сочетании с одной из следующих процедур:
          <ul class="ce-list">
            <li>
              Соответствие типу на основе внутреннего контроля производства и
              контролируемые испытания продукции (Приложение VI к директиве
              2014/34/EU)
            </li>
            <li>
              Соответствие типу на основе обеспечения качества продукции
              (Приложение VII к директиве 2014/34/EU)
            </li>
          </ul>
        </li>
        <li>
          б) Остальное оборудование в этих группах и категориях проходит
          внутренний контроль производства (Приложение VIII к директиве
          2014/34/EU). Техническая документация, предусмотренная в пункте 2
          Приложения VIII к директиве 2014/34/EU, передается для хранения в
          уполномоченный орган
        </li>
      </ul>
    </li>

    <li>
      3) для оборудования категории 3 группы II: Внутренний контроль
      производства (Приложение VIII к директиве 2014/34/EU) Оборудование групп
      I и II может также проходить подтверждение соответствия на основе
      верификации единицы (Приложение IX к директиве 2014/34/EU)
    </li>
  </ul>
  <p>&nbsp;</p>

  <h3>Правила и условия нанесения знака соответствия CE</h3>

  <p>
    Знак соответствия СЕ наносится на изделие или на табличку технических
    данных таким образом, чтобы он был хорошо видим, разборчив и несмываем.
  </p>
  <p>
    Если характеристики продукции не позволяют это сделать или это не
    оправдано, знак соответствия должен наноситься на упаковку и на
    сопроводительные документы, если действующее законодательство
    предусматривает эти документы.
  </p>
  <p>
    Знак соответствия СЕ наносится до экспорта. Рядом с ним может
    проставляться пиктограмма или любой другой знак, обозначающий опасность
    или особый способ применения.
  </p>
  <p>
    После знака соответствия СЕ указывается идентификационный номер
    нотифицированного органа, если орган участвует на этапе производственного
    контроля и это определено одним из модулей сертификации.
  </p>
  <p>
    Идентификационный номер нотифицированного органа наносится самим органом
    или по его указанию изготовителем или полномочным представителем.
  </p>
  <p>&nbsp;</p>`,
    en: `<h2 class="ce-us-title">
    Product <span class="ce-us-title-accent">certification</span>
  </h2>
  <h3>Regulation No. 2016/425</h3>
  <p>Scope:</p>
  <ul class="ce-list">
    <li>
      a) equipment designed and manufactured to be worn or held by a person
      for protection against one or more risks to that person's health or
      safety;
    </li>
    <li>
      b) interchangeable components for equipment referred to in point a)
      which are essential for its protective function;
    </li>
    <li>
      c) connexons systems for equipment referred to in point a) that are not
      held or worn by a person, that are designed to connect that equipment to
      an external device or to a reliable anchorage point, that are not
      designed to be permanently fixed and that do not require fastening works
      before use.
    </li>
  </ul>
  <p>PPE is classified according to risk categories:</p>
  <ul class="ce-list">
    <li>
      Category I includes exclusively the following minimal risks:
      <ul class="ce-list">
        <li>a) superficial mechanical injury;</li>
        <li>
          b) contact with cleaning materials of weak action or prolonged
          contact with water;
        </li>
        <li>c) contact with hot surfaces not exceeding 50 °C;</li>
        <li>
          d) damage to the eyes due to exposure to sunlight (other than during
          observation of the sun);
        </li>
        <li>e) atmospheric conditions that are not of an extreme nature.</li>
      </ul>
    </li>
    <li>
      Category II includes risks other than those listed in Categories I and
      III.
    </li>
    <li>
      Category III includes exclusively the risks that may cause very serious
      consequences such as death or irreversible damage to health relating to
      the following:
      <ul class="ce-list">
        <li>a) substances and mixtures which are hazardous to health;</li>
        <li>b) atmospheres with oxygen deficiency;</li>
        <li>c) harmful biological agents;</li>
        <li>d) ionising radiation;</li>
        <li>
          e) high-temperature environments the effects of which are comparable
          to those of an air temperature of at least 100 °C;
        </li>
        <li>
          f) low-temperature environments the effects of which are comparable
          to those of an air temperature of -50 °C or less;
        </li>
        <li>g) falling from a height;</li>
        <li>h) electric shock and live working;</li>
        <li>i) drowning;</li>
        <li>j) cuts by hand-held chainsaws;</li>
        <li>k) high-pressure jets;</li>
        <li>l) bullet wounds or knife stabs;</li>
        <li>m) harmful noise.</li>
      </ul>
    </li>
  </ul>
  <p>Conformity assessment procedures:</p>
  <ul class="ce-list">
    <li>
      Category I: internal production control (module A) set out in Annex IV;
    </li>
    <li>
      Category II: EU type-examination (module B) set out in Annex V, followed
      by conformity to type based on internal production control (module C)
      set out in Annex VI;
    </li>
    <li>
      Category III: EU type-examination (module B) set out in Annex V, and
      either of the following:
      <ul class="ce-list">
        <li>
          conformity to type based on internal production control plus
          supervised product checks at random intervals (module C2) set out in
          Annex VII;
        </li>
        <li>
          conformity to type based on quality assurance of the production
          process (module D) set out in Annex VIII.
        </li>
      </ul>
    </li>
    <li>
      For PPE produced as a single unit to fit an individual user and
      classified according to Category III, the procedure referred to in point
      b) may be followed.
    </li>
  </ul>

  <h3>Directive 2014/34/EU (ATEX)</h3>
  <p>Scope:</p>
  <p>Directive shall apply to the following:</p>
  <ul class="ce-list">
    <li>
      a) equipment and protective systems intended for use in potentially
      explosive atmospheres;
    </li>
    <li>
      b) safety devices, controlling devices and regulating devices intended
      for use outside potentially explosive atmospheres but required for or
      contributing to the safe functioning of equipment and protective systems
      with respect to the risks of explosion;
    </li>
    <li>
      c) components intended to be incorporated into equipment and protective
      systems referred to in point (a).
    </li>
  </ul>

  <p>Conformity assessment procedures:</p>
  <ul class="ce-list">
    <li>
      a) for equipment-groups I and II, equipment-categories M 1 and 1, the
      EU-type examination set out in Annex III, in conjunction with either of
      the following:
      <ul class="ce-list">
        <li>
          Conformity to type based on quality assurance of the production
          process set out in Annex IV,
        </li>
        <li>
          Conformity to type based on product verification set out in Annex V;
        </li>
      </ul>
    </li>
    <li>
      b) for equipment-groups I and II, equipment categories M 2 and 2:
      <ul class="ce-list">
        <li>
          i) in the case of internal combustion engines and electrical
          equipment in these groups and categories, the EU-type examination
          set out in Annex III, in conjunction with either of the following:
          <ul class="ce-list">
            <li>
              Conformity to type based on internal production control plus
              supervised product testing set out in Annex VI,
            </li>
            <li>
              Conformity to type based on product quality assurance set out in
              Annex VII;
            </li>
          </ul>
        </li>
        <li>
          ii) in the case of other equipment in these groups and categories,
          internal production control set out in Annex VIII and the
          communication of the technical documentation provided for in Annex
          VIII, point 2, to a notified body, which shall acknowledge receipt
          of it as soon as possible and shall retain it;
        </li>
      </ul>
    </li>
    <li>
      c) for equipment-group II, equipment category 3, internal production
      control set out in Annex VIII;
    </li>
    <li>
      d) for equipment-groups I and II, in addition to the procedures referred
      to in points (a), (b) and (c) of this paragraph, conformity based on
      unit verification set out in Annex IX may also be followed.
    </li>
  </ul>

  <p>&nbsp;</p>`,
    ua: `<h2 class="ce-us-title">
        <span class="ce-us-title-accent">Сертифікація</span> продукції
    </h2>

    <h3>
        Регламент № 2016/425
        <p>Засоби індивідуального захисту (ЗІЗ).</p>
    </h3>
    <p>Сфера застосування:</p>
    <ul class="ce-list">
        <li>
            а) обладнання, яке людина носить або використовує, щоб захистити себе
            від одного або декількох ризиків, які загрожують її здоров'ю або
            безпеці;
        </li>
        <li>
            б) змінні компоненти обладнання, зазначеного в пункті а), які
            необхідні для того, щоб воно виконувало захисні функції;
        </li>
        <li>
            в) з'єднувальні системи обладнання, зазначеного в пункті а). З їх
            допомогою обладнання підключається до зовнішніх пристроїв або
            приєднується до надійної точки кріплення, які не спроектовані як
            постійно незнімні і не потребують прикріплення перед застосуванням.
        </li>
    </ul>
    <p>ЗІЗ класифікуються відповідно до категорій ризиків:</p>
    <ul class="ce-list">
        <li>
            Категорія I включає - наступні мінімальні ризики – поверхневе
            механічне пошкодження: контакт з миючими засобами слабкої дії
            або тривалий контакт з водою; контакт з гарячими поверхнями,
            температура яких не перевищує 50 °C; пошкодження очей через
            вплив сонячного світла (крім спостереження за сонцем); атмосферні
            умови, які не мають екстремального характеру.
        </li>
        <li>
            Категорія II включає ризики, які не відносяться до перерахованих у категоріях
            I та III.
        </li>
        <li>
            Категорія III включає виключно ризики, які можуть призвести до
            серйозних наслідків - завдати смерть або необоротний збиток здоров'ю
            користувача, що стосується таких факторів: речовини і суміші, небезпечні для
            здоров'я; середовища з дефіцитом кисню; шкідливі біологічні агенти;
            іонізуюче випромінювання; середовища з високою температурою, вплив
            яких порівнюється з середовищами з температурою повітря не менше 100 °C;
            середовища з низькою температурою, вплив яких порівнюється з середовищами
            з температурою повітря - 50 °C або нижче; падіння з висоти; ураження
            електричним струмом і робота під напругою; утоплення; порізи від
            ручних бензопил; струми високого тиску; вогнепальні поранення або порізи
            ножем; шкідливий шум.
        </li>
    </ul>
    <p>Процедури оцінки відповідності:</p>
    <ul class="ce-list">
        <li>Для категорії I - Внутрішній контроль виробництва (модуль А)</li>
        <li>
            Для категорії II - Експертиза типу (модуль В) і оцінка відповідності
            типу, заснована на внутрішньому виробничому контролі (модуль С)
        </li>
        <li>
            Для категорії III Експертиза типу (модуль В) і на вибір виробника
            будь-який з перерахованих нижче модулів:
            <ul class="ce-list">
                <li>
                    оцінка відповідності типу, заснована на внутрішньому контролі
                    виробництва спільно з контрольованими перевірками продукції,
                    проводимими у випадкові проміжки часу (модуль С2)
                </li>
                <li>
                    оцінка відповідності типу, заснована на процесі забезпечення
                    якості продукції (модуль D)
                </li>
            </ul>
        </li>
        <li>
            Для одиночної продукції категорії ІІІ - Експертиза типу (модуль В) і
            оцінка відповідності типу, заснована на внутрішньому виробничому
            контролі (модуль С).
        </li>
    </ul>
    <p>&nbsp;</p>
    <h3>Директива 2014/34/EU (ATEX)</h3>
<p>Область застосування:</p>
<p>
    Обладнання та захисні
    системи, призначені для використання в потенційно вибухонебезпечних
    середовищах; захисні, контролюючі та регулюючі пристрої,
    призначені для використання поза потенційно вибухонебезпечними середовищами,
    які необхідні або сприяють вибухобезпечному функціонуванню
    обладнання та захисних систем; компоненти, призначені для встановлення
    в обладнання та захисні системи, призначені для використання в
    потенційно вибухонебезпечних середовищах.
</p>
<p>Обладнання поділяється на наступні групи:</p>
<ul class="ce-list">
    <li>
        група I – обладнання, призначене для використання в підземних
        частинах шахт та в частинах наземних установок шахт, які можуть опинитися
        під загрозою з'явлення рудничного газу та/або горючого пилу, включаючи
        обладнання категорій М1 та М2;
    </li>
    <li>
        група II – обладнання, призначене для використання в місцях,
        відмінних від шахт або наземних шахтових установок, які можуть опинитися
        під загрозою з'явлення вибухонебезпечних середовищ, включаючи обладнання категорій
        1, 2 та 3.
    </li>
</ul>
<p>
    Обладнання категорії М1 та категорії 1 повинно забезпечувати надзвичайно
    високий рівень захисту.
</p>
<p>
    Обладнання категорії М2 та категорії 2 повинно забезпечувати високий
    рівень захисту.
</p>
<p>
    Обладнання категорії 3 – повинно забезпечувати нормальний рівень захисту.
</p>
<p>Процедура оцінки відповідності директиві 2014/34/EU</p>
<ul class="ce-list">
    <li>
        1) для обладнання категорії М1 групи I та категорії 1 групи II:
        Іспити типу (Додаток III до директиви 2014/34/EU) в поєднанні з
        однією з наступних процедур: Відповідність типу на основі забезпечення
        якості процесу виробництва (Додаток IV до директиви 2014/34/EU)
        Відповідність типу на основі верифікації продукції (Додаток V до
        директиви 2014/34/EU)
    </li>
    <li>
        2) для обладнання категорії М2 групи I та категорії 2 групи II:
        <ul class="ce-list">
            <li>
                а) Двигуни внутрішнього згоряння та електричне обладнання
                проходять іспити типу (Додаток III до директиви 2014/34/EU) в
                поєднанні з однією з наступних процедур:
                <ul class="ce-list">
                    <li>
                        Відповідність типу на основі внутрішнього контролю виробництва та
                        контрольовані іспити продукції (Додаток VI до директиви
                        2014/34/EU)
                    </li>
                    <li>
                        Відповідність типу на основі забезпечення якості продукції
                        (Додаток VII до директиви 2014/34/EU)
                    </li>
                </ul>
            </li>
            <li>
                б) Решта обладнання в цих групах та категоріях проходить
                внутрішній контроль виробництва (Додаток VIII до директиви
                2014/34/EU). Технічна документація, передбачена в пункті 2
                Додатка VIII до директиви 2014/34/EU, передається для зберігання
                в уповноважений орган
            </li>
        </ul>
    </li>
    <li>
        3) для обладнання категорії 3 групи II: Внутрішній контроль
        виробництва (Додаток VIII до директиви 2014/34/EU) Обладнання груп
        I та II також може проходити підтвердження відповідності на основі
        верифікації одиниці (Додаток IX до директиви 2014/34/EU)
    </li>
</ul>
<p>&nbsp;</p>

<h3>Правила та умови нанесення знака відповідності CE</h3>

<p>
    Знак відповідності СЕ наноситься на виріб або на табличку технічних
    даних таким чином, щоб він був добре видимий, чіткий та стійкий до вимивання.
</p>
<p>
    Якщо характеристики продукції не дозволяють це зробити або це не
    обгрунтовано, знак відповідності повинен наноситися на упаковку та на
    супровідні документи, якщо чинне законодавство передбачає ці документи.
</p>
<p>
    Знак відповідності СЕ наноситься до експорту. Поруч з ним може
    ставитися піктограма або будь-який інший знак, що вказує на небезпеку
    або особливий спосіб використання.
</p>
<p>
    Після знаку відповідності СЕ вказується ідентифікаційний номер
    повідомленого органу, якщо орган бере участь на етапі виробничого
    контролю та це передбачено одним з модулів сертифікації.
</p>
<p>
    Ідентифікаційний номер повідомленого органу наноситься самим органом
    або за його вказівкою виробником або уповноваженим представником.
</p>
<p>&nbsp;</p>
`,
  },
  cert: {
    ru: `<div class="container lng-cert">
    <h2 class="cert-us-title">
      <span class="cert-us-title-accent">Сертификация</span> систем управления
    </h2>
    <p class="cert-us-subtitle">
      <strong
        >СЕРТИФИКАЦИОННЫЙ ЭКСПЕРТНЫЙ ЦЕНТР АСУ БАЛТИК
      </strong>
      выполняет сертификацию в соответствии с требованиями следующих
      международных стандартов:
    </p>
    <div class="cert-us-texts">
      <div class="cert-left">
        <ul class="cert-list">
          <li class="cert-step lng-cert-item1">
            <strong> EN ISO 9001 Системы управления качеством</strong>
            базовый стандарт, определяющий основы успешной деятельности
            предприятия и гарантирующий, что высокое качество продукции и услуг
            останется неизменным с течением времени.
          </li>
          <li class="cert-step lng-cert-item2">
            <strong> ISO 14001 Системы управления окружающей средой</strong
            >международный стандарт, используемый предприятием, которое
            стремится к оптимальному использованию природных ресурсов и энергии,
            защите и сохранению природы в ее неповторимости и ограничению рисков
            экологических катастроф.
          </li>
          <li class="cert-step lng-cert-item3">
            <strong>
              EN ISO 45001 Системы управления по охране здоровья и безопасным
              условиям труда</strong
            >является гарантией соблюдения всех юридических и оперативных
            требований по контролю и улучшению безопасных и безвредных условий
            труда.
          </li>          
          <li class="cert-step lng-cert-item5">
            <strong>Стандарт GMP - Good Manufacturing Practice </strong
            >представляет собой систему принципов и правил, которые контролируют
            правильность протекания всех стадий производственного процесса и
            гарантируют качество конечного продукта.
          </li>
          <li class="cert-step lng-cert-item4">
            <strong> ISO 13485 - Медицинские изделия</strong> определяет
            требования к системе менеджмента качества, которые могут применяться
            организацией при проектировании, разработке, производстве, монтаже и
            обслуживании медицинских изделий, а также при проектировании,
            разработке и обеспечении связанных с ними услуг в случаях, когда
            организации необходимо продемонстрировать способность поставлять
            медицинские изделия, отвечающие как требованиям потребителя, так и
            требованиям, применимым к этим медицинским изделиям
          </li>

          <li class="cert-step lng-cert-item6">
            <strong>
              EN ISO 22000 - Система управления безопасностью пищевых
              продуктов</strong
            >предназначен для проведения сертификации систем менеджмента
            безопасности пищевых продуктов организаций, участвующих в пищевой
            цепи, перерабатывающих или производящих: продукцию с малым и с
            большим сроком хранения, пищевые ингредиенты, животноводческую
            продукцию, упаковку для пищевых продуктов, оборудование, очищающие
            средства.
          </li>
          <li class="cert-step lng-cert-item7">
            <strong> ISO 50001 Системы управления энергией</strong>применяется
            ко всем типам энергоносителей и устанавливает требования по
            внедрению плана энергетического мониторинга и анализа
            энергоэффективности.
          </li>
          <li class="cert-step lng-cert-item8">
            <strong>
              EN ISO 39001 Системы управления безопасностью дорожного
              движения</strong
            >стандарт, предоставляющий инструмент, благодаря которому
            организации смогут сократить и в конечном итоге устранить риск
            смерти и серьезных телесных повреждений при дорожно-транспортных
            происшествиях. Стандарт определяет элементы надлежащей практики для
            управления безопасностью дорожного движения (БДД), что позволит
            организации добиться желаемых результатов в отношении БДД.
          </li>
          <li class="cert-step lng-cert-item9">
            <strong>
              EN ISO 27001 Системы управления информационной
              безопасностью</strong
            >система управления безопасностью информации обеспечивает
            систематическое исследование рисков безопасности вашей организации,
            включительно воздействия, угрозы и уязвимость.
          </li>
          <li class="cert-step lng-cert-item9">
            <strong>
              EN ISO 20000-1 Информационные технологии. Управление
              услугами</strong
            >международный ИТ-стандарт, позволяющий компаниям демонстрировать
            высокие достижения и наилучшую практику в управлении ИТ-услугами
          </li>
        </ul>
        <p>
          АСУ Балтик проводит работы по оценке соответствия и сертификации в
          соответствии с требованиями
          <strong> ISO/IEC 17021-1, IAF MD 1, IAF MD 2, IAF MD 5.</strong>
        </p>
      </div>

      <div class="cert-center">
        <h3 class="lng-center-title">Этапы сертификации систем управления</h3>
        <ul class="cert-steps">
          <li>
            <h4 class="lng-center-sub-title1">Планирование аудита</h4>
            <p class="lng-center-sub-text1">
              Начинается с заполнения клиентом заявки на сертификацию по
              выбранному стандарту, заключения договора и разработки программы
              сертификации.
            </p>
          </li>
          <li>
            <h4 class="lng-center-sub-title2">Сертификация</h4>
            <p class="lng-center-sub-text2">
              Включает в себя проведение аудита первого этапа для проверки
              документации на соответствие требованиям стандарта и
              сертификационного аудита второго этапа для оценки применения и
              эффективности системы на месте. Процесс завершается выдачей
              сертификата со сроком действия 3 года.
            </p>
          </li>
          <li>
            <h4 class="lng-center-sub-title1">Надзор</h4>
            <p class="lng-center-sub-text1">
              В течение следующих трех лет раз в год проводится надзорный аудит
              для подтверждения того, что сертифицированная система продолжает
              соответствовать требованиям.
            </p>
          </li>
          <li>
            <h4 class="lng-center-sub-title1">Ресертификация</h4>
            <p class="lng-center-sub-text1">
              Продление сертификата на новый трехлетний период посредством
              проведения аудита системы.
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>`,
    en: `<div class="container  lng-cert">
      <h2 class="cert-us-title">
        <span class="cert-us-title-accent">Management System</span> Certification
      </h2>
      <p class="cert-us-subtitle">
        <strong
          >CERTIFICATION EXPERT CENTER ASU BALTIC</strong
        >
        performs certification in accordance with the requirements of the following
        international standards:
      </p>
      <div class="cert-us-texts">
        <div class="cert-left">
          <ul class="cert-list">
            <li class="cert-step lng-cert-item1">
              <strong> EN ISO 9001 Quality Management Systems</strong
              >a foundational standard that defines the principles of successful
              enterprise activity and ensures that the high quality of products and
              services remains consistent over time.
            </li>
            <li class="cert-step lng-cert-item2">
              <strong> ISO 14001 Environmental Management Systems</strong
              >an international standard used by enterprises that strive for optimal
              use of natural resources and energy, nature protection and
              preservation, and the mitigation of environmental risks.
            </li>
            <li class="cert-step lng-cert-item3">
              <strong>
                EN ISO 45001 Occupational Health and Safety Management Systems</strong
              >provides a guarantee of compliance with all legal and operational
              requirements for monitoring and improving safe and harmless working
              conditions.
            </li>
            <li class="cert-step lng-cert-item5">
              <strong>Good Manufacturing Practice (GMP) Standard</strong
              >represents a system of principles and rules that control the proper
              progression of all stages of the production process and ensure the
              quality of the final product.
            </li>
            <li class="cert-step lng-cert-item4">
              <strong>ISO 13485 - Medical Devices</strong>defines requirements for
              the quality management system that can be applied by an organization
              involved in the design, development, production, assembly, and
              servicing of medical devices, as well as in the design, development,
              and provision of related services in cases where organizations need to
              demonstrate the ability to supply medical devices that meet both
              consumer and applicable requirements for these medical devices.
            </li>
  
            <li class="cert-step lng-cert-item6">
              <strong>
                EN ISO 22000 - Food Safety Management System</strong
              >intended for the certification of food safety management systems of
              organizations involved in the food chain, processing or producing:
              products with short and long shelf life, food ingredients, livestock
              products, packaging for food products, equipment, cleaning agents.
            </li>
            <li class="cert-step lng-cert-item7">
              <strong>ISO 50001 Energy Management Systems</strong>applies to all
              types of energy sources and establishes requirements for the
              implementation of an energy monitoring and energy efficiency analysis
              plan.
            </li>
            <li class="cert-step lng-cert-item8">
              <strong>
                EN ISO 39001 Road Traffic Safety Management System</strong
              >a standard that provides a tool for organizations to reduce and,
              ultimately, eliminate the risk of death and serious bodily injuries in
              road traffic accidents. The standard defines the elements of proper
              practice for road traffic safety management (RTSM), which will enable
              organizations to achieve desired results in terms of RTSM.
            </li>
            <li class="cert-step lng-cert-item9">
              <strong>
                EN ISO 27001 Information Security Management Systems</strong
              >the information security management system provides a systematic
              examination of the security risks of your organization, including
              impact, threat, and vulnerability.
            </li>
            <li class="cert-step lng-cert-item9">
              <strong>
                EN ISO 20000-1 Information Technology. Service Management</strong
              >an international IT standard that allows companies to demonstrate high
              achievements and best practices in IT service management.
            </li>
          </ul>
          <p>
            ASU BALTIC conducts conformity assessment and certification activities
            in accordance with the requirements of
            <strong> ISO/IEC 17021-1, IAF MD 1, IAF MD 2, IAF MD 5.</strong>
          </p>
        </div>
  
        <div class="cert-center">
          <h3 class="lng-center-title">Stages of Management System Certification</h3>
          <ul class="cert-steps">
            <li>
              <h4 class="lng-center-sub-title1">Audit Planning</h4>
              <p class="lng-center-sub-text1">
                Begins with the client filling out a certification application for the chosen standard,
                signing a contract, and developing a certification program.
              </p>
            </li>
            <li>
              <h4 class="lng-center-sub-title2">Certification</h4>
              <p class="lng-center-sub-text2">
                Involves conducting a first-stage audit to verify the documentation's compliance with the standard's requirements
                and a second-stage certification audit to assess the application and effectiveness of the system on-site.
                The process concludes with the issuance of a certificate for a three-year term.
              </p>
            </li>
            <li>
              <h4 class="lng-center-sub-title1">Surveillance</h4>
              <p class="lng-center-sub-text1">
                Over the next three years, an annual surveillance audit is conducted to confirm that the certified system continues to meet the requirements.
              </p>
            </li>
            <li>
              <h4 class="lng-center-sub-title1">Recertification</h4>
              <p class="lng-center-sub-text1">
                Extension of the certificate for a new three-year period through a system audit.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>  
  `,
    ua: `
    <div class="container lng-cert">
      <h2 class="cert-us-title">
        <span class="cert-us-title-accent">Сертифікація</span> систем управління
      </h2>
      <p class="cert-us-subtitle">
        <strong
          >СЕРТИФІКАЦІЙНИЙ ЕКСПЕРТНИЙ ЦЕНТР АСУ БАЛТІК </strong
        >
        здійснює сертифікацію відповідно до вимог наступних міжнародних стандартів:
      </p>
      <div class="cert-us-texts">
        <div class="cert-left">
          <ul class="cert-list">
            <li class="cert-step lng-cert-item1">
              <strong> EN ISO 9001 Системи управління якістю</strong
              >базовий стандарт, що визначає основи успішної діяльності підприємства
              і гарантує, що висока якість продукції та послуг залишиться
              незмінною з плином часу.
            </li>
            <li class="cert-step lng-cert-item2">
              <strong> ISO 14001 Системи управління навколишнім середовищем</strong
              >міжнародний стандарт, який використовується підприємствами, що прагнуть
              до оптимального використання природних ресурсів та енергії, захисту та
              збереження природи в її неповторності та обмеженню ризиків
              екологічних катастроф.
            </li>
            <li class="cert-step lng-cert-item3">
              <strong>
                EN ISO 45001 Системи управління охороною здоров'я та безпечними
                умовами праці</strong
              >є гарантією дотримання всіх юридичних та оперативних вимог щодо
              контролю та покращення безпечних та безшкідливих умов праці.
            </li>
            <li class="cert-step lng-cert-item5">
              <strong>Стандарт GMP - Good Manufacturing Practice </strong
              >представляє собою систему принципів і правил, які контролюють
              правильність перебігу всіх стадій виробничого процесу і гарантують
              якість кінцевого продукту.
            </li>
            <li class="cert-step lng-cert-item4">
              <strong> ISO 13485 - Медичні вироби</strong>визначає вимоги до системи
              управління якістю, які можуть застосовуватися організацією при
              проектуванні, розробці, виробництві, монтажі та обслуговуванні
              медичних виробів, а також при проектуванні, розробці та забезпеченні
              пов'язаних з ними послуг у випадках, коли організації потрібно
              продемонструвати здатність постачати медичні вироби, які
              відповідають як вимогам споживача, так і вимогам, що застосовуються
              до цих медичних виробів.
            </li>
  
            <li class="cert-step lng-cert-item6">
              <strong>
                EN ISO 22000 - Система управління безпекою харчових
                продуктів</strong
              >призначена для проведення сертифікації систем управління безпекою
              харчових продуктів організацій, що беруть участь у харчовому ланцюгу,
              переробляють або виробляють: продукцію з малим і великим строком
              зберігання, харчові інгредієнти, тваринницьку продукцію, упаковку
              для харчових продуктів, обладнання, очищувальні засоби.
            </li>
            <li class="cert-step lng-cert-item7">
              <strong> ISO 50001 Системи управління енергією</strong>застосовується до
              всіх типів енергоносіїв і встановлює вимоги до впровадження плану
              енергетичного моніторингу та аналізу енергоефективності.
            </li>
            <li class="cert-step lng-cert-item8">
              <strong>
                EN ISO 39001 Системи управління безпекою дорожнього
                руху</strong
              >стандарт, що надає інструмент, завдяки якому організації зможуть
              зменшити і, в кінцевому підсумку, усунути ризик смерті і серйозних
              тілесних ушкоджень при дорожньо-транспортних пригодах. Стандарт
              визначає елементи належної практики для управління безпекою
              дорожнього руху (БДД), що дозволить організації досягти бажаних
              результатів щодо БДД.
            </li>
            <li class="cert-step lng-cert-item9">
              <strong>
                EN ISO 27001 Системи управління інформаційною
                безпекою</strong
              >система управління безпекою інформації забезпечує систематичне
              дослідження ризиків безпеки вашої організації, включаючи вплив,
              загрозу та вразливість.
            </li>
            <li class="cert-step lng-cert-item9">
              <strong>
                EN ISO 20000-1 Інформаційні технології. Управління
                послугами</strong
              >міжнародний ІТ-стандарт, що дозволяє компаніям демонструвати високі
              досягнення та найкращу практику у керуванні ІТ-послугами.
            </li>
          </ul>
          <p>
            АСУ Балтік здійснює роботи з оцінки відповідності та сертифікації відповідно до вимог
            <strong> ISO/IEC 17021-1, IAF MD 1, IAF MD 2, IAF MD 5.</strong>
          </p>
        </div>
  
        <div class="cert-center">
          <h3 class="lng-center-title">Етапи сертифікації систем управління</h3>
          <ul class="cert-steps">
            <li>
              <h4 class="lng-center-sub-title1">Планування аудиту</h4>
              <p class="lng-center-sub-text1">
                Починається з заповнення клієнтом заявки на сертифікацію за обраним стандартом,
                укладання договору та розробки програми сертифікації.
              </p>
            </li>
            <li>
              <h4 class="lng-center-sub-title2">Сертифікація</h4>
              <p class="lng-center-sub-text2">
                Включає проведення аудиту першого етапу для перевірки документації на відповідність вимогам стандарту
                та сертифікаційного аудиту другого етапу для оцінки застосування та ефективності системи на місці.
                Процес завершується видачею сертифіката на три роки.
              </p>
            </li>
            <li>
              <h4 class="lng-center-sub-title1">Нагляд</h4>
              <p class="lng-center-sub-text1">
                Протягом наступних трьох років раз на рік проводиться наглядовий аудит для підтвердження того,
                що сертифікована система продовжує відповідати вимогам.
              </p>
            </li>
            <li>
              <h4 class="lng-center-sub-title1">Ресертифікація</h4>
              <p class="lng-center-sub-text1">
                Продовження сертифіката на новий трьохрічний період за допомогою проведення аудиту системи.
              </p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  
  `,
  },
};
